import { createReducer } from 'typesafe-actions'
import {
  addArchiveChatHandle,
  addChatHandle,
  addMessageOnActiveChatHandle,
  answerChatHandle,
  clearActiveChatHandle,
  closeChatHandle,
  deleteMessageFromChatHandle,
  loadChatsBySearchHandle,
  loadChatsByStatusHandle,
  loadMessagesByChatIdHandle,
  readMessagesFromChatHandle,
  removeArchiveChatHandle,
  setActiveChatHandle,
  setActiveChatMessageHandle,
  setActiveChatMessagesHandle,
  setHasMoreChatsHandle,
  updateActiveChatHandle,
  updateArchiveChatHandle,
  updateChatHandle,
  updateChatFirebaseHandle,
  transferChatHandle,
  transferChatToOperatorHandle,
  cancelChatHandle,
  watchChatsCountHandle,
  createNewChatHandle
} from './handles'
import { archiveChatHandle } from './handles/archiveChatAction'
import { deleteChatHandle } from './handles/deleteChatAction'
import { fetchMoreChatsHandle } from './handles/fetchMoreChatsAction'
import { ChatAction, ChatState } from './types'

const initialState: ChatState = {
  isLoadingDeleteChat: false,
  isLoadingArchiveChat: false,
  isLoadingActiveChatMessagesSuccess: false,
  isLoadingReadMessagesFromChat: false,
  isErrorOnReadMessagesFromChat: false,
  isErrorOnDeleteChat: false,
  isErrorOnArchiveChat: false,
  chats: [],
  archiveChats: [],
  activeChat: null,
  infiniteScrollBottomHack: false,
  hasMoreOpenChats: false,
  hasMoreInProgressChats: false,
  hasMoreFinishedChats: false,
  openChats: [],
  inProgressChats: [],
  finishedChats: [],
  isErrorOnLoadingOpenChats: false,
  isErrorOnLoadingInProgressChats: false,
  isErrorOnLoadingFinishedChats: false,
  isLoadingOpenChats: false,
  isLoadingInProgressChats: false,
  isLoadingInProgressChatsSearchApi: false,
  isSuccessInProgressChatsSearchApi: false,
  isLoadingFinishedChats: false,
  isErrorOnAnswerChat: false,
  isLoadingAnswerChat: false,
  isErrorOnMessagesByChatId: false,
  isLoadingOnLoadMessagesByChatId: false,
  isSuccessOnMessagesByChatId: false,
  loadMessagesByChatIdError: null,
  isLoadingCreateNewChat: false,
  isErrorOnCreateNewChat: null,
  finishedChatsSearchApi: [],
  inProgressChatsSearchApi: [],
  openChatsSearchApi: [],
  isErrorOnUpdateChatFirebase: false,
  isLoadingUpdateChatFirebase: false,
  isErrorOnTransferChat: false,
  isLoadingTransferChat: false,
  isSuccessOnTransferChat: false,
  isErrorOnTransferChatToOperator: false,
  isLoadingTransferChatToOperator: false,
  isSuccessTransferChatToOperator: false,
  isErrorOnCustomButtonCall: false,
  isLoadingCustomButtonCall: false,
  isSuccessOnCustomButtonCall: false,
  isLoadingCancelChat: false,
  isSuccessOnCancelChat: false,
  isErrorOnCancelChat: false,
  chatsCount: { open: 0, in_progress: 0, close: 0 }
}

export const chatReducer = createReducer<ChatState, ChatAction>(initialState)
  .handleAction(addChatHandle.asyncAction, addChatHandle.success)
  .handleAction(closeChatHandle.asyncAction.success, closeChatHandle.success)
  .handleAction(updateChatFirebaseHandle.asyncAction.request, updateChatFirebaseHandle.request)
  .handleAction(updateChatFirebaseHandle.asyncAction.success, updateChatFirebaseHandle.success)
  .handleAction(updateChatFirebaseHandle.asyncAction.failure, updateChatFirebaseHandle.failure)
  .handleAction(updateChatHandle.asyncAction, updateChatHandle.success)
  .handleAction(addArchiveChatHandle.asyncAction, addArchiveChatHandle.success)
  .handleAction(updateArchiveChatHandle.asyncAction, updateArchiveChatHandle.success)
  .handleAction(removeArchiveChatHandle.asyncAction, removeArchiveChatHandle.success)
  .handleAction(setActiveChatHandle.asyncAction, setActiveChatHandle.success)
  .handleAction(setActiveChatMessageHandle.asyncAction, setActiveChatMessageHandle.success)
  .handleAction(setActiveChatMessagesHandle.asyncAction, setActiveChatMessagesHandle.success)
  .handleAction(loadMessagesByChatIdHandle.asyncAction.request, loadMessagesByChatIdHandle.request)
  .handleAction(loadMessagesByChatIdHandle.asyncAction.success, loadMessagesByChatIdHandle.success)
  .handleAction(loadMessagesByChatIdHandle.asyncAction.failure, loadMessagesByChatIdHandle.failure)
  .handleAction(addMessageOnActiveChatHandle.asyncAction, addMessageOnActiveChatHandle.success)
  .handleAction(readMessagesFromChatHandle.asyncAction.request, readMessagesFromChatHandle.request)
  .handleAction(readMessagesFromChatHandle.asyncAction.success, readMessagesFromChatHandle.success)
  .handleAction(readMessagesFromChatHandle.asyncAction.failure, readMessagesFromChatHandle.failure)
  .handleAction(answerChatHandle.asyncAction.request, answerChatHandle.request)
  .handleAction(answerChatHandle.asyncAction.success, answerChatHandle.success)
  .handleAction(answerChatHandle.asyncAction.failure, answerChatHandle.failure)
  .handleAction(deleteChatHandle.asyncAction.request, deleteChatHandle.request)
  .handleAction(deleteChatHandle.asyncAction.success, deleteChatHandle.success)
  .handleAction(deleteChatHandle.asyncAction.failure, deleteChatHandle.failure)
  .handleAction(archiveChatHandle.asyncAction.request, archiveChatHandle.request)
  .handleAction(archiveChatHandle.asyncAction.success, archiveChatHandle.success)
  .handleAction(archiveChatHandle.asyncAction.failure, archiveChatHandle.failure)
  .handleAction(updateActiveChatHandle.asyncAction, updateActiveChatHandle.success)
  .handleAction(deleteMessageFromChatHandle.asyncAction, deleteMessageFromChatHandle.success)
  .handleAction(clearActiveChatHandle.asyncAction, clearActiveChatHandle.success)
  .handleAction(setHasMoreChatsHandle.asyncAction, setHasMoreChatsHandle.success)
  .handleAction(loadChatsByStatusHandle.asyncAction.request, loadChatsByStatusHandle.request)
  .handleAction(loadChatsByStatusHandle.asyncAction.success, loadChatsByStatusHandle.success)
  .handleAction(loadChatsByStatusHandle.asyncAction.failure, loadChatsByStatusHandle.failure)
  .handleAction(loadChatsBySearchHandle.asyncAction.request, loadChatsBySearchHandle.request)
  .handleAction(loadChatsBySearchHandle.asyncAction.success, loadChatsBySearchHandle.success)
  .handleAction(loadChatsBySearchHandle.asyncAction.failure, loadChatsBySearchHandle.failure)
  .handleAction(fetchMoreChatsHandle.asyncAction.success, fetchMoreChatsHandle.success)
  .handleAction(transferChatHandle.asyncAction.request, transferChatHandle.request)
  .handleAction(transferChatHandle.asyncAction.success, transferChatHandle.success)
  .handleAction(transferChatHandle.asyncAction.failure, transferChatHandle.failure)
  .handleAction(transferChatToOperatorHandle.asyncAction.request, transferChatToOperatorHandle.request)
  .handleAction(transferChatToOperatorHandle.asyncAction.success, transferChatToOperatorHandle.success)
  .handleAction(transferChatToOperatorHandle.asyncAction.failure, transferChatToOperatorHandle.failure)
  .handleAction(cancelChatHandle.asyncAction.request, cancelChatHandle.request)
  .handleAction(cancelChatHandle.asyncAction.success, cancelChatHandle.success)
  .handleAction(cancelChatHandle.asyncAction.failure, cancelChatHandle.failure)
  .handleAction(watchChatsCountHandle.asyncAction.success, watchChatsCountHandle.success)
  .handleAction(watchChatsCountHandle.asyncAction.failure, watchChatsCountHandle.failure)
  .handleAction(createNewChatHandle.asyncAction.request, createNewChatHandle.request)
  .handleAction(createNewChatHandle.asyncAction.success, createNewChatHandle.success)
  .handleAction(createNewChatHandle.asyncAction.failure, createNewChatHandle.failure)
